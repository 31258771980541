<template>
  <div>
    <heads :msg="title"></heads>
    <div class="box">
      <div class="xiao">
        <div v-for="(item,index) in list" :key="index">
          <div class="cont">
            <div class="bian"></div>
            <div class="tops">
              <div class="tu">
                <img :src="item.avatar" alt="">
              </div>
              <div class="zi">
                <div class="shang">
                  <span class="s1">{{item.user_name}}</span
                  ><span class="s2" v-if="false">{{item.post_appellation}}</span>
                  <p class="pp">
                    <span class="s3">单位：</span>
                    <span class="s4">{{item.work_study_place}}</span>
                  </p>
                </div>
              </div>
              <div class="sixin" v-if="false">
                <button v-show="false">私信</button>
              </div>
            </div>
          </div>
          <div class="bottoms">

            <img v-if="index===0" src="../../icon/jin.png" />
            <img v-else-if="index===1" src="../../icon/yin.png" />
            <img v-else-if="index===2" src="../../icon/tong.png" />
            <span v-else class="span_num">{{index+1}}</span>

            <span class="s5">总成绩：</span><span class="s6">{{item.sum_score}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { chakantongxue } from "@/utils/index1.js";
import tool from '@/utils/tools.js'
import heads from '@/components/common/heads.vue'
export default {
  components: {
    heads
  },
  data() {
    return {
      title:"班级同学",
      lesson_id:'',
      class_id:'',
      student_id:'',
      class_name:'',
      list: [],
    };
  },
  created() {
    this.lesson_id = this.$route.query.lesson_id
    this.class_id = this.$route.query.class_id
    this.student_id = this.$route.query.student_id
    this.class_name = this.$route.query.class_name
    this.chankan_tongxue(); //查看同学
  },
  methods: {
    //查看同学
    chankan_tongxue() {
      var params = {
        lesson_id: this.lesson_id,
        class_id: this.class_id,
        page:1,
        limit:10000
      };
      chakantongxue(params).then((res) => {
        console.log(res);
        this.list = res.result.list;
      });
    },
    tui() {
      if (tool.isInApp()) {
        if (tool.isIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
                  'vue_backApp'
                  , '返回'
                  , function(responseData) {
                    // android 返参数接受
                  }
          )
        }
      } else {
        this.$router.go(-1)
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = "#f1f1f1";
    next();
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = "";
    next();
  },
};
</script>

<style lang="scss" scoped>
.s5 {
  font-size: 30px;
  margin-top: 28px;
  margin-left: 40px;
  font-weight: 400;
}
.s6 {
  font-size: 40px;
  margin-top: 27px;
  color: red;
  // margin-left: 40px;
}
.bottoms {
  width: 90%;
  height: 90px;
  margin-left: 43px;
  // background: chartreuse;
  margin-top: -90px;
  display: flex;
  img {
    width: 50px;
    height: 65px;
    margin-top: 16px;
    margin-left: 30px;
  }
  .span_num{
    display: inline-block;
    text-align: right;
    margin-top: 20px;
    width: 68px;
    height: 65px;
    padding-right: 12px;
    line-height: 65px;
    font-size: 30px;
    color: #222222;
  }
}
.pp {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.sixin {
  width: 230px;
  height: 90px;
  // background: chartreuse;
  button {
    width: 80px;
    height: 45px;
    border: none;
    border-radius: 10px;
    margin-left: 130px;
    margin-top: 23px;
    background: rgb(255, 255, 255);
    border: 1px solid #000;
  }
}
.s1 {
  font-weight: 600;
  font-size: 30px;
}
.s2 {
  color: rgb(157, 157, 157);
  margin-left: 10px;
}
.s3 {
  width: 80px;
  color: rgb(103, 103, 103);
}
.s4 {
  width: 450px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(103, 103, 103);
}
.zi {
  margin-left: 20px;
  height: 90px;
}
.tu {
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  // background: #000;
  // margin-top: 20px;
  border-radius: 50%;
  img{
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
}
.tops {
  width: 96%;
  height: 134px;
  margin-left: 2%;
  // background: seagreen;
  border-bottom: 1px solid rgb(190, 182, 182);
  display: flex;
  justify-content: start;
  align-items: center;
}
.xiao {
  overflow: hidden;
  overflow-y: scroll;
}
.box {
  width: 100%;
  padding-bottom: 40px;
  // margin-top: -20px;
  overflow: hidden;
}
.bian {
  width: 12px;
  height: 100%;
  background: red;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.cont {
  width: 690px;
  height: 224px;
  background: rgb(255, 255, 255);
  margin: 0 auto;
  // margin-top: 20px;
  border-radius: 10px;
  margin-top: 30px;
  display: flex;
}
header {
  width: 100%;
  // height:200px;
  height: 110px;
  background: white;
  // position: fixed;
  // top: 0;
  // left: 0;
  // margin-top: -199px;
  z-index: 999;
  // position: absolute;
  // top: 0;
  // left: 0;
  ul {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 40px;
    text-align: center;
    li {
      width: 200px;
      height: 60px;
      margin-top: 60px;
    }
  }
}
.img1 {
  width: 50px;
  height: 50px;
  margin-left: -100px;
  margin-top: 5px;
}
</style>
